import React, { useEffect, useState } from 'react'
import { graphql, Link, withPrefix } from 'gatsby'

import Layout from '../components/layout'
import Meal from '../components/Meal'

const monthsList = [
  'ian',
  'feb',
  'mar',
  'apr',
  'mai',
  'iun',
  'iul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
]

const getTodayDate = (today = new Date()) => {
  const day = today.getDate()
  const month = monthsList[today.getMonth()]
  return { day, month }
}
const getDayLink = (date = getTodayDate()) =>
  `/meals/${date.month}-${String(date.day).padStart(2, '0')}`

const DAY_IN_MS = 24 * 60 * 60 * 1000
const addDays = days => new Date(Date.now() + days * DAY_IN_MS)
const getLinkFor = days => getDayLink(getTodayDate(addDays(days)))

const hoursBreakpoints = [8, 11, 13, 16, 19]
const getCurentMealId = () => {
  const hour = new Date().getHours()
  return hoursBreakpoints.findIndex(b => b > hour - 1)
}

const IndexPage = () => {
  const [day, setDay] = useState('')
  const [meals, setMeals] = useState([])
  const [images, setImages] = useState([])

  useEffect(() => {
    fetch(withPrefix(`/page-data${getDayLink()}/page-data.json`))
      .then(response => response.json())
      .then(data => {
        const images = []
        data.result.data.allFile.edges
          .map(_ => _.node)
          .forEach(image => {
            images[image.name.substr(-1)] = image
          })
        const { day, meals } = data.result.data.mealsJson
        setDay(day)
        setMeals(meals)
        setImages(images)
      })
  }, [])

  useEffect(() => {
    const id = getCurentMealId()
    if (id > 0 && meals.length > 0) {
      setTimeout(() => {
        document?.querySelector(`#meal-${id}`).scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }, 200)
    }
  }, [meals])
  const today = getTodayDate()
  return (
    <Layout>
      <h6>
        <Link to={getLinkFor(-1)}>Meniul de ieri</Link> -{' '}
        <Link to={getLinkFor(1)}>Meniul de mâine</Link>
      </h6>
      <h2>
        Meniul de azi - {day}, {today.day} {today.month}
      </h2>
      {meals.map((meal, i) => (
        <Meal key={meal.period} meal={meal} image={images[i]} id={i} />
      ))}
    </Layout>
  )
}

export const query = graphql`
  {
    site {
      pathPrefix
    }
  }
`

export default IndexPage
